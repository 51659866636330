import { createRouter, createWebHistory } from 'vue-router'
 //import Home from '../views/Home.vue'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/backend/login.vue'),
    meta: {
      index: 1
    }
  },
  {
    path: '/', //带参数，在params.period时可以获取
    
    name: '/',
    component: () => import( '@/views/index.vue'),
    
  },
  {
    path: '/backend', //带参数，在params.period时可以获取
    
    name: 'backend',
    component: () => import( '@/views/backend/index.vue'),
    
  }
  ,
  {
    path: '/video/videoshow', //带参数，在params.period时可以获取
    
    name: 'viedeoshow',
    component: () => import( '@/views/video/videoshow.vue'),
    
  }
  ,
  {
    path: '/video/videoseries', //带参数，在params.period时可以获取
    
    name: 'videoSeries',
    component: () => import( '@/views/video/videoSeries.vue'),
    
  },
  {
    path: '/video/hotvideo', //带参数，在params.period时可以获取
    
    name: 'hotVideo',
    component: () => import( '@/views/video/hotVideo.vue'),
    
  },
  {
    path: '/video/sysuser', //带参数，在params.period时可以获取
    
    name: 'videoUser',
    component: () => import( '@/views/video/sysUser.vue'),
    
  }
  ,
  {
    path: '/video/playlist', //带参数，在params.period时可以获取
    
    name: 'playList',
    component: () => import( '@/views/video/playList.vue'),
    
  }
  ,
  {
    path: '/video/likelist', //带参数，在params.period时可以获取
    
    name: 'likeList',
    component: () => import( '@/views/video/likeList.vue'),
    
  }
  ,
  {
    path: '/video/pageShow', //带参数，在params.period时可以获取    
    name: 'pageSHow',
    component: () => import( '@/views/video/pageshow.vue'),
    
  },
  {
    path: '/exam/examSeriesList', //带参数，在params.period时可以获取    
    name: 'examSeriesList',
    component: () => import( '@/views/exam/examSeriesList.vue'),
    
  }
  ,
  {
    path: '/exam/examSeries', //带参数，在params.period时可以获取    
    name: 'examSeries',
    component: () => import( '@/views/exam/examSeries.vue'),
    
  }
  ,
  {
    path: '/mall/productlist', //带参数，在params.period时可以获取    
    name: 'productlist',
    component: () => import( '@/views/mall/productList.vue'),
    
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
