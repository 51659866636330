const en = {
   
        "登录" : "login",
        "手机号码":"Mobile",
        "请输入":"Please fill ",
        "证件号码":"Id Card No",
        "确认绑定":"Bind Account",
        "账号登录":"Go to Login",
        "随便逛逛":"Have A Look",
        "系统账号":"Account",
        "登录密码":"password",
        "账号登录":"sign in",
        "返回绑定":"back to bind",
        "重新发送":"Resend",
        "温馨提示":"Tip",
        "登录提醒":"bind with you mobile and id card No.if did not registed in system, will lead you to regist page",
        "登录提醒账号":"Only the internal account ",
        "个人基本信息":"Basic personal information",
        "身份证号":"Id Card No",
        "性别":"Gender ",
        "男":"male",
        "女":"female",
        "姓名":"Name",
        "费别":"Fee",
        "医保":"Insurance",
        "自费":"Non Insurance",
        "出生日期":"Birthday",
        "婚姻":"Marriage",
        "已婚":"Married",
        "未婚":"unmarried",
        "联系电话":"Mobile",
        "电话":"Mobile",
        "联系人":"Contacts",
        "家庭住址":"Address",
        "住址":"Address",
        "药物过敏史":"Alergic",
        "提交保存":"Submit",
        "取消返回":"Cancel to Return",
        "健康档案":"Medical Record",
        "入院日期":"Admission date",
        "入院科室":"Admission Department",
        "月经史":"Menstrual history",
        "末次月经":"Last menstruation",
        "月经周期（天）":"Menstrual cycle（days）",
        "孕次":"Gravida",
        "产次":"Parity",
        "流产数":"Abortion number",
        "经期（天）":"period（days）",
        "存活胎儿数":"Number of surviving fetuses",
        "既往病史":"Previous",
        "其他病史":"Other medical history",
        "无":"No",
        "有":"Yes",
        "家族史":"Family",
        "传染病史":"Infectious",
        "个人病史":"Personal",
        "盆腔手术史":"Pelvic surgery",
        "患者病程":"Patient course",
        "登记病程":"Registry disease duration",
        "加标签":"Tag",
        "发信息":"Send message",
        "取消关闭":"Cancel to close",
        "类别":"category",
        "记录病程":"Record course",
        "日期":"date",
        "识别":"discriminate",
        "语音":"voice",
        "图片":"picture",
        "小视频":"video",
        "保存资料":"keep data",
        "请直接语音描述病程信息":"Please describe the course of disease directly by voice",
        "报告时间":" Report time",
        "检查部位":" Inspection part",
        "检查所见":" Findings",
        "诊断信息":" Diagnostic information",
        "项目名称（参数值）":"Project name (parameter value) ",
        "项目结果":"Project result",
        "起始日期":"Starting date",
        "结束日期":"End date",
        "科室":"Department",
        "报告时间":"Report time",
        "未找到相关记录":"No recoeds were found",
        "上传图片结果":"Upload the picture result",
        "过敏史":"Allergic",
        "请输入过敏史":"Please input Allergic ",
        "个人史":"Personal",
        "请输入个人史":"Please input Personal",
        "既往史":"Previous",
        "请输入既往史":"Please input Previous",
        "家族史":"Family",
        "请输入家族史":"Please input Family",
        "婚育史":"Obsterical",
        "请输入婚育史":"Please input Obsterical",
        "现病史":"Present",
        "请输入现病史":"Please input Present",
        "可选标签":"Optional tag",
        "添加标签":"Place tag",
        "所有":"All",
        "当天":"The day",
        "我的":"Mine",
        "筛选":"Filter",
        "登记":"Registry",
        "登记时间":"Registry time",
        "查询过滤":"Query filtering",
        "患者姓名":"Patient's name",
        "请输入患者姓名":"Please input Patient's name",
        "联系电话":"Contact number",
        "请输入联系电话":"Please input Contact number",
        "登记日期":"Registry date",
        "点击选择":"Click select",
        "确认查询":"Confirm",
        "没有什么描述":"Nothing to describe",
        "金额":"Money",
        "发起时间":"Initiate time",
        "元":"yuan",
        "过期时间":"overdue time",
        "状态":"state",
        "返回订单":"return order",
        "无标题":"No title",
        "单价":"Unit price",
        "数量":"quantity",
        "金额":"money",
        "没有其他信息了":"No other information", 
        "请点击选择":"Click select",
        "申请":"Apply surgery date ",
        "手术日期":"surgery date",
        "门诊诊断":"Outpatient diagnosis",
        "备注信息":"Remarks information",
        "床位":"Bed",
        "主管医生":"doctor in charge",
        "主管护士":"nurse in charge",
        "保存资料":"Save data",
        "推送分组":"Push group",
        "信息概览":"Information overview",
        "确认选择":"Cofirm select",
        "所有":"All",
        "待入":"Stay ",
        "医生":"doctor",
        "出院时间":"Discharge time",
        "住院天数":"length of stay",
        "住院诊断":"diagnosis",
        "上传病历":"upload the medical history",
        "有效期":"term of validity",
        "没有找到对应记录":"No corresponding record found",
        "收住院":"Admission",
        "出院":"leave hospita",
        "余额":"Balance",
        "有效期":"valid period",
        "没有找到对应记录":"No corresponding record found",
        "在线预约":"Online booking",
        "登记注册":"Registration",
        "获取":"Get",
        "验证码":"Code",
        "错误":" error",
        "已发送":" is sent",
        "请选择":"Please select ",
        "无法获取用户的微信资料":"Unable to get wechat information",
        "无法获取用户的openId":"Unable to get openId",
        "下一步":"Next step",
        "您好":"Hello",
        "upload": "upload",
        "个人信息":"Profile",
        "切换样式":"Change Style",
        "登录/注册":"Login/Register",
        "电邮我们":"Email Us",
"地图":"Map",
"服务热线":"Hot Line",
"我的订单":"My Orders",
"搜索":"Search",
"请输入产品或服务":"Please Input Product or Service",
"购物车":"Cart",
"语言":"Lang",
"在线预约":"Book Service",
"健康商城":"Health Mall",
"热门套餐":"Hot Package",
"基因检测":"Gen Detect",
"体检套餐":"PE Package",
"热卖商品":"Hot Products",
"关于我们":"About Us",
"地址":"Address",
"营业时间":"Buss Time",
"星期一至五":"Mon-Fri",
  "星期六":"SatusDay",
   "星期日及公众假期休息":"Closed on Sundays and public holidays",
   "标准体检套餐":"Standard PE package",
   "健康资讯":"Health Information",
   "我们的服务":"Our Services",
}
export default en;



