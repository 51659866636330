const cn = {
    
        "登录" : "登录",
        "手机号码":"手机号码",
        "请输入":"请输入 ",
        "证件号码":"证件号码",
        "确认绑定":"确认绑定",
        "账号登录":"账号登录",
        "随便逛逛":"随便逛逛",
        "系统账号":"系统账号",
        "登录密码":"登录密码",
        "账号登录":"账号登录",
        "返回绑定":"返回绑定",
        "重新发送":"重新发送",
        "温馨提示":"温馨提示",
        "登录提醒":"首次建档用户请直接输入手机号和身份证并点击绑定，系统如未找到会直接进入注册页面",
        "登录提醒账号":"登录系统已有账号，仅限内部账号 ",
        "个人基本信息":"个人基本信息",
        "身份证号":"身份证号",
        "性别":"性别",
        "男":"男",
        "女":"女",
        "姓名":"姓名",
        "费别":"费别",
        "医保":"医保",
        "自费":"自费",
        "出生日期":"出生日期",
        "婚姻":"婚姻",
        "已婚":"已婚",
        "未婚":"未婚",
        "年龄":"年龄",
        "电话":"电话",
        "联系电话":"联系电话",
        "联系人 ":"联系人 ",
        "联系人":"联系人",
        "家庭住址 ":"家庭住址 ",
        "住址 ":"住址 ",
        "家庭住址":"家庭住址",
        "药物过敏史 ":"药物过敏史 ",
        "药物过敏史":"药物过敏史",
        "提交保存":"提交保存",
        "取消返回":"取消返回",
        "健康档案":"健康档案",
        "入院日期":"入院日期",
        "入院科室":"入院科室",
        "月经史":"月经史",
        "末次月经":"末次月经",
        "月经周期":"月经周期",
        "孕次 ":"孕次 ",
        "产次 ":"产次 ",
        "流产数 ":"流产数 ",
        "经期（天） ":"经期（天） ",
        "存活胎儿数 ":"存活胎儿数 ",
        "既往病史 ":"既往病史 ",
        "其他病史 ":"其他病史 ",
        "无":"无",
        "有":"有",
        "家族史":"家族史",
        "传染病史":"传染病史",
        "个人病史":"个人病史",
        "盆腔手术史":"盆腔手术史",
        "患者病程":"患者病程",
        "登记病程":"登记病程",
        "加标签":"加标签",
        "发信息":"发信息 ",
        "取消关闭":"取消关闭",
        "类别":"类别",
        "记录病程":"记录病程",
        "日期":"日期",
        "识别":"识别",
        "语音":"语音",
        "图片":"图片",
        "小视频":"小视频",
        "保存资料":"保存资料",
        "请直接语音描述病程信息":"请直接语音描述病程信息",
        "讲完了":"讲完了",
        "报告时间":"报告时间",
        "检查部位":"检查部位",
        "检查所见":" 检查所见",
        "诊断信息":"诊断信息",
        "项目名称（参数值）":"项目名称（参数值)",
        "项目结果":"项目结果",
        "起始日期":"起始日期",
        "结束日期":"结束日期",
        "科室":"科室",
        "报告时间":"报告时间",
        "未找到相关记录":"未找到相关记录",
        "上传图片结果":"上传图片结果",
        "过敏史":"过敏史",
        "请输入过敏史":"请输入过敏史 ",
        "个人史":"个人史",
        "请输入个人史":"请输入个人史",
        "既往史":"既往史",
        "请输入既往史":"请输入既往史",
        "家族史":"家族史",
        "请输入家族史":"请输入家族史",
        "婚育史":"婚育史",
        "请输入婚育史":"请输入婚育史",
        "现病史":"现病史",
        "请输入现病史":"请输入现病史",
        "可选标签":"可选标签",
        "添加标签":"添加标签",
        "所有":"所有",
        "当天":"当天",
        "我的":"我的",
        "筛选":"筛选",
        "登记":"登记",
        "登记时间":"登记时间",
        "查询过滤":"查询过滤",
        "患者姓名":"患者姓名",
        "请输入患者姓名":"请输入患者姓名",
        "联系电话":"联系电话",
        "请输入联系电话":"请输入联系电话",
        "登记日期":"登记日期",
        "点击选择":"点击选择",
        "确认查询":"确认查询",
        "没有什么描述":"没有什么描述",
        "金额":"金额",
        "发起时间":"发起时间",
        "元":"元",
        "过期时间":"过期时间",
        "状态":"状态",
        "返回订单":"返回订单",
        "无标题":"无标题",
        "单价":"单价",
        "数量":"数量",
        "没有其他信息了":"没有其他信息了", 
        "请点击选择":"请点击选择",
        "申请":"申请",
        "手术日期":"手术日期",
        "门诊诊断":"门诊诊断",
        "备注信息":"备注信息",
        "床位":"床位",
        "主管医生":"主管医生",
        "主管护士":"主管护士",
        "保存资料":"保存资料",
        "推送分组":"推送分组",
        "信息概览":"信息概览",
        "确认选择":"确认选择",
        "所有":"所有",
        "待入":"待入",
        "医生":"医生",
        "出院时间":"出院时间",
        "住院天数":"住院天数",
        "住院诊断":"住院诊断",
        "上传病历":"上传病历",
        "收住院":"收住院",
        "出院":"出院",
        "余额":"余额",
        "有效期":"有效期",
        "没有找到对应记录":"没有找到对应记录",
        "登记注册":"登记注册",
        "获取":"获取",
        "验证码":"验证码",
        "错误":"错误",
        "已发送":"已发送",
        "请选择":"请选择",
        "无法获取用户的微信资料":"无法获取用户的微信资料",
        "无法获取用户的openId":"无法获取用户的openId",
        "下一步":"下一步",
        "您好":"您好",
        "upload": "上传",
        "个人信息":"个人信息",
"切换样式":"切换样式",
"登录/注册":"登录/注册",
"电邮我们":"电邮我们",
    "地图":"地图",
    "服务热线":"服务热线",
    "我的订单":"我的订单",
    "搜索":"搜索",
    "请输入产品或服务":"请输入产品或服务",
    "购物车":"购物车",
    "语言":"语言",
    "在线预约":"在线预约",
    "健康商城":"健康商城",
    "热门套餐":"热门套餐",
"基因检测":"基因检测",
 "体检套餐":"体检套餐",
 "热卖商品":"热卖商品",
 "关于我们":"关于我们",
 "地址":"地址",
 "营业时间":"营业时间",
 "星期一至五":"星期一至五",
    "星期六":"星期六",
    "星期日及公众假期休息":"星期日及公眾假期休息",
    "标准体检套餐":"标准体检套餐",
    "健康资讯":"健康资讯",
    "我们的服务":"我们的服务",
    
}
export default cn;



