<template>
  <div style=" width:100%; text-align:center; margin:auto;">
    <div style="background-color:#eee; line-height:40px;">
<div class="content PCView" >
  <el-row :gutter="0" >
            <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1" @click="switchMenuCollapse">
          
            </el-col>
            <el-col :xs="4" :sm="6" :md="10" :lg="16" :xl="5" class="roleName">
              </el-col
            >
            <el-col :xs="4" :sm="4" :md="4" :lg="1" :xl="4" v-if="msgCount>0">
              <el-icon size="large"><ShoppingCart /></el-icon>
             
            </el-col>
            <el-col :xs="8" :sm="1" :md="1" :lg="2" :xl="1" v-if="_LoginUser.userName==null">
              
              {{$t("登录/注册")}}
            </el-col>
            <el-col :xs="8" :sm="1" :md="1" :lg="2" :xl="1"  >
              |  {{$t("我的订单")}}|</el-col>
            <el-col :xs="8" :sm="4" :md="4" :lg="3" :xl="1" >
               
              {{$t("语言")}}：
              <el-dropdown :hide-on-click="false" @command="swithLanguage" style="line-height:40px;">
                <span class="el-dropdown-link">
                  {{_LoginUser.language=="cn"?"简体中文":(_LoginUser.language=="en"?"English":"繁体中文")}}

                  <el-icon class="el-icon--right"><arrow-down /></el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item  command="cn">
                      <span class="pf-opt-name">简体中文</span>
                    </el-dropdown-item>

                    <el-dropdown-item command="en">
                      <span class="pf-opt-name">English</span>
                    </el-dropdown-item>
                    <el-dropdown-item command="big">
                      <span class="pf-opt-name">繁体中文</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown></el-col
            >
            <el-col :xs="8" :sm="5" :md="5" :lg="4" :xl="2" v-if="_LoginUser.userName!=null">
              <el-dropdown :hide-on-click="false">
                <span class="el-dropdown-link">
                  <el-icon size="large"><UserFilled /></el-icon>

                  <span class="javascript:;"
                    >
                    {{ $t("您好") }}
                   
                    ！{{ _LoginUser.userName }}
                  </span>

                  <el-icon class="el-icon--right"><arrow-down /></el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item>
                      <el-icon><User /></el-icon>
                      <span class="pf-opt-name">{{ $t("个人信息") }}</span>
                    </el-dropdown-item>

                   
                    <el-dropdown-item>
                      <el-icon><Lock /></el-icon>
                      <span class="pf-opt-name">修改密码</span>
                    </el-dropdown-item>
                    <el-dropdown-item @click="logout">
                      <el-icon><SwitchButton /></el-icon>

                      <span class="pf-opt-name">退出</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown></el-col
            >
          </el-row>
        
          
          </div>

          <div style="background-color:#fff; line-height:40px; padding:20px;" class="PCView">
            <img src="/images/logo.png" style="height:40px; vertical-align:middle">
            <el-input
      v-model="input4"
      style="width: 40%"
      :placeholder="$t('请输入产品或服务')"
      @focus="goSearch"
      size="large"
    >
      <template #prefix>
        <el-icon class="el-input__icon"><search /></el-icon>
      </template>
      <template #append>
        <el-button type="info">{{$t("搜索")}}</el-button>
      </template>
    </el-input>

    <el-button size="large" style="margin-left:50px;"> <el-icon><ShoppingCart /></el-icon> {{ $t("购物车") }}</el-button>
  </div>
</div>

    <!--滚屏展播-->
<div v-if="carouselItems.length>0">
  <el-carousel ref="carouselRef" arrow="always"  :height="carouselHeight+'px'"  @touchstart="handleTouchStart"  @touchend="handleTouchEnd">
    <el-carousel-item v-for="child in carouselItems" :key="child" >
      <img
      style="width:100%;"
        :src="child.bGPic"
        fit="contain"
      />

    </el-carousel-item>
  </el-carousel>
  <hr/>
</div>
  <div > 
    <div class="content" >







        <div v-for="module in datas" :key="index">


<!--视频搜寻-->
<div v-if="module.showType == '51'">
<el-input
      v-model="input4"
      style="width: 100%"
      placeholder="產品或服務"
      @focus="goSearch"
      size="large"
    >
      <template #prefix>
        <el-icon class="el-input__icon"><search /></el-icon>
      </template>
      <template #append>
        <el-button type="info">搜索</el-button>
      </template>
    </el-input>
    </div>
<!--4分组-->
<div v-if="module.showType == '4'">
   
  <el-row :gutter="10">
    <el-col
      :xs="6"
      :sm="6"
      :md="4"
      :lg="3"
      :xl="1"
      v-for="child in module.children"
      :key="child"
    >
    
      <div class="grid-content col-4-item"> <icon
        :class="'iconfont icon-'+child.icon"
        :style="'color:'+(child.color?child.color:'#3366CC')"
      ></icon>
      <p> {{ child.name }}</p>
       </div>
    </el-col>
  </el-row>
</div>

<!--2分组-->
<div v-if="module.showType == '2'">
  <el-row :gutter="30">
    <el-col
      :xs="12"
      :sm="12"
      :md="12"
      :lg="6"
      :xl="4"
      v-for="child in module.children"
      :key="child"
    >
     
      <div class="grid-content col-2-item">
        <icon
        :class="'iconfont icon-'+child.icon"
        :style="'color:'+(child.color?child.color:'#3366CC')"
      ></icon>
        {{ $t(child.name) }}</div>
      
    </el-col>
  </el-row>
</div>


<!--2分组 有描述-->
<div v-if="module.showType == '22'&&module.description=='有描述'" class="col-22-item">
<div class="title" >

  {{ $t(module.name) }}
  </div>
  <el-row :gutter="30">
    <el-col
      :xs="12"
      :sm="12"
      :md="12"
      :lg="6"
      :xl="4"
      v-for="child in module.children"
      :key="child"
    >
     
      <div class="grid-content col-2-item">
        <div style="line-height:30px;" v-if="child.bGPic==null||child.bGPic==''">
         
         <el-text  truncated  :style="'line-height:40px;font-size:24px;color:'+(child.color?child.color:'#3366CC')" >
           {{ $t(child.name) }}
 </el-text>
         <el-text line-clamp="8" :style="'text-align:left;font-size:20px;color:'+(child.color?child.color:'#3366CC')">
           {{ $t(child.description) }}
 </el-text>
         </div>

        <div style="width:50%; float:left;line-height:25px;" v-if="child.bGPic!=null&&child.bGPic!=''">
         
          <el-text  truncated style="font-size:20px;line-height:40px;">
            {{ $t(child.name) }}
  </el-text>
          <el-text line-clamp="3">
            {{ $t(child.description) }}
  </el-text>
          </div>
          <div style="width:50%; float:left" v-if="child.bGPic!=null">
            <img :src=child.bGPic  style="width:100%;"/>
        
          </div>
       
        
        </div>
      
    </el-col>
  </el-row>
</div>
<!--25分组-->
<div v-if="module.showType == '25'" class="col-25-item">
  <div  v-for="child in module.children" :key="child">
  <div class="title">
    {{ $t(child.name) }}
    </div>
    <el-row :gutter="30">
    <el-col
      :xs="12"
      :sm="12"
      :md="4"
      :lg="6"
      :xl="4"
     v-for="grandson in child.children"
      :key="grandson"
    >
     
      <div class="grid-content ">
       <img :src="grandson.bGPic" />
     
       </div>
        <div class="name"> {{ $t(grandson.name) }}
          <span style="float:right; margin-right:10px;">${{ grandson.description }}</span>
          </div>
    </el-col>
  </el-row>

    </div>
  
</div>

<!--视频多显-->
<div v-if="module.showType == '55'" style="width:100%; overflow-x: auto;" class="videoMultiItem">
  <div style="background-color: #333; line-height:40px;margin-bottom: 10px;">
  <el-text class="mx-1" size="large" style="margin-left: 10px;color:#fff; ">{{module.name}}</el-text>
  <el-link   style="float: right;margin-right: 10px; color:#fff" @click="showMore(module.code)">更多>></el-link>
</div> 
  <div style="max-height: 500px; overflow: hidden;'">
    <ul>
        <li   v-for="child in module.children"
      :key="child" @click="ShowVideo(child.code)">
    <img       
        :src="child.bGPic"        
      />
      <div style="clear: both;"> <el-text truncated tag="b" style="color:#fff;">{{child.name}}</el-text>
        <p>
      <el-text truncated style="clear:both;font-size: 11px;color:#fff;">{{child.description}}</el-text>
    </p>
      </div>
 
    </li>
    </ul>
</div>
</div>

</div>



</div>

<div style="height:80px;line-height:80px; text-align:left; font-size:24px;background:url('/images/footer_bg.jpg')">
  <div class="content">
  <el-row :gutter="30">
    <el-col
      :xs="12"
      :sm="12"
      :md="12"
      :lg="8"
      :xl="4"
    style="padding-top:20px;"
    >
     
      <a href="tel://0592-5971709"  >

      <img src="/images/tel.png" style="height:40px;vertical-align:middle;float:left; " /> 
      <div  style="line-height:20px; float:left; font-size:18px;margin-left:10px;">
       <span> {{$t("服务热线")}}：</span><br/>
       <span>(852)62667535</span>
        
        </div>
     
       </a>
    </el-col>
    <el-col
      :xs="12"
      :sm="12"
      :md="12"
      :lg="8"
      :xl="4"
     style="padding-top:20px;"
    >
     
      <a href="mailto://masonlu@163.com" >
        <img src="/images/email.png" style="height:40px;vertical-align:middle;float:left; " /> 
      <div  style="line-height:40px; float:left; font-size:18px;margin-left:10px;">
       <span> {{$t("电邮我们")}}</span>
        
        </div>
    
       </a>
    </el-col>
    <el-col
      :xs="12"
      :sm="12"
      :md="8"
      :lg="8"
      :xl="4"
     style="padding-top:20px;"
    >
    <a href="aboutus" >
        <img src="/images/map.png" style="height:40px;vertical-align:middle;float:left; " /> 
      <div  style="line-height:40px; float:left; font-size:18px;margin-left:10px;">
       <span> {{$t("地图")}}</span>
        
        </div>
    </a>
    </el-col>
  </el-row>
</div>
  </div>
  <div >
  <div class="content ft">
  <el-row :gutter="30">
    <el-col
      :xs="12"
      :sm="12"
      :md="12"
      :lg="8"
      :xl="4"
    
    >
     <div style="font-weight:bold;">
      {{$t("关于我们")}}
      </div>
     <p>
      {{$t("电话")}}:(852)62667535/90612995
      </p>
      <p>
     WhatsApp:(852)62667536
      </p>
      <p>
     Email:profExam@gmail.com
      </p>
    </el-col>
    <el-col
      :xs="12"
      :sm="12"
      :md="12"
      :lg="8"
      :xl="4"
    
    >
     
    <div style="font-weight:bold;">
      {{$t("地址")}}
      </div>
     <p>
      香港九龍佐敦彌敦道301號-309號嘉賓商業大廈1313A室
      </p>
      <p>
        Rm1313A,13/F.,Champion Building,301-309 Nathan Road,Jordan,Kowloon,Hong Kong
      </p>
      <p>

      </p>
    </el-col>
    <el-col
      :xs="12"
      :sm="12"
      :md="8"
      :lg="8"
      :xl="4"
    
    >
    <div style="font-weight:bold;">
      {{$t("营业时间")}}
      </div>
     <p>
      {{$t("星期一至五")}} 9am–1pm,2pm–6pm;
    </p>
      <p>
        {{$t("星期六")}} 9am–1pm, 2pm–5pm;
      </p>
      <p>
        {{$t("星期日及公众假期休息")}}
      </p>
    </el-col>
  </el-row>
</div>
  </div>


        
        <div style="height:80px;"></div>
</div>
<!--底下Tab栏-->
<div  class="bottomTab MView">
    <el-row :gutter="10">
    <el-col
      :xs="6"
      :sm="6"
      :md="6"
      :lg="6"
      :xl="6"
      @click="goHome()"
    >
    
      <div class="grid-content  tabSelected"> <icon
        class="iconfont icon-home"></icon>
      <p>首页</p>
       </div>
    </el-col>

    <el-col
      :xs="6"
      :sm="6"
      :md="6"
      :lg="6"
      :xl="6"
    @click="goSeries(1)"
    >
    
      <div class="grid-content 、 "> <icon
        class="iconfont icon-fenlei"></icon>
      <p>商城</p>
       </div>
    </el-col>
    <el-col
    :xs="6"
      :sm="6"
      :md="6"
      :lg="6"
      :xl="6"
      @click="goHotVideo()"
    >
    
      <div class="grid-content "> <icon
        class="iconfont icon-zhiliaoshifuzhen"></icon>
      <p>咨询</p>
       </div>
    </el-col>
    <el-col
    :xs="6"
      :sm="6"
      :md="6"
      :lg="6"
      :xl="6"
      @click="goUser()"
    >
    
      <div class="grid-content "> <icon
        class="iconfont icon-user"></icon>
      <p>个人中心</p>
       </div>
    </el-col>

  </el-row>
    </div>
</div>
</template>
  
  
  
  
  <script setup>
import appconfig from "@/utils/config"; //常用方法
import { ElMessage, TabsInstance, ElCarousel } from "element-plus";
import { inject, reactive, ref, watch, onMounted } from "vue";
import { isNull, post, Request, navigateTo } from "@/api/common"; //常用方法
import { useI18n } from "vue-i18n";
//import searchPage from "./hospital/searchpage";
import pageInfoShow from "./base/pageInfoShow";

import bottomTab from "./common/bottomTab";
const carouselRef = ref(null);
const { locale } = useI18n();
// 获取全局对象`
// 获取全局对象`
const global = inject("global");
let carouselHeight = ref(200); //总记录数
const pageInfoShowRef = ref(null);
let ownerType = ref(""); //查询关键字
let scrHeight = ref(400); //总记录数
let datas = ref([]);
let carouselItems = ref([]);
let menuItems = ref([]);
let _LoginUser = reactive({language:"ft"}); //当前用户
onMounted(() => {
  window.addEventListener("resize", handleResize);
  var width = window.screen.width;
  
  carouselHeight.value = 500*width/1920;
  //获取菜单
  //scrHeight.value = window.screen.height - 85;

  /*if (!isNull(global.store.state.userInfo)) {
    //判断下是否有登录
    _LoginUser = global.store.state.userInfo;
    if(Request("openId")!=null&&_LoginUser.openId!= Request("openId")){
      Login();
    }else{
      RetrieveMpModule();
    }
  }else{
    Login();
  }*/
 var lang=localStorage.getItem('lang');
 if(lang==null) lang="ft";
 _LoginUser.language=lang;
  RetrieveMpModule();
  //ElMessage(scrHeight.value.toString());
});
function handleResize() {
  var width = window.screen.width;
  
  carouselHeight.value = 500*width/1920;
 
}

const RetrieveMpModule = function () {
  var param = new Object();
  param.orgCode = appconfig.orgCode;
  post("health/GetMyMpModule", param, function (result) {
    datas.value = result.datas;
    result.datas.forEach((element) => {
      if (element.showType == "9") carouselItems.value = element.children;

      if (element.showType == "4") menuItems.value = element.children;

      /* if(element.showType == '91'){ //保存底部导航选项
        localStorage.setItem("BottomTab", JSON.stringify(element));
      }*/
    });
    if (menuItems.value.length > 0) {
      ownerType.value=menuItems.value[0].name;
      pageInfoShowRef.value.setOwnerType(menuItems.value[0].name);
    }
  });
};

const swithLanguage=(lang)=>{ //切换语言
    _LoginUser.language=lang;
    global.store.commit('setUserInfo', _LoginUser);
    locale.value = lang;  
    localStorage.setItem('lang', lang)
localStorage.setItem('LoginUser', JSON.stringify(_LoginUser))
  //保存到数据库

}

const handlerMenuChange = function (key) {
 // if (keyPath.length < 1) return;
  ownerType.value=menuItems.value[key].name;
  pageInfoShowRef.value.setOwnerType(menuItems.value[key].name);
};

let startX;
const handleTouchStart = (event) => {
  startX = event.touches[0].clientX;
};

const handleTouchEnd = (event) => {
  const currentX = event.changedTouches[0].clientX;
  const delta = currentX - startX;

  if (delta > 100) {
    // 向左滑动
    // 执行上滑操作
    //swapeDown();
    //  carouselRef.value.prev();
  } else if (delta < -100) {
    //向右
    // 向上滑动
    //swapeUp();
    //console.log('向上滑动');
    // carouselRef.value.next();
  }
};
</script>
  
  <style scoped>
  .content a{
    color:#555;
    text-decoration: none;
  }
.menu-type {
  clear: both;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.col-2-item:nth-child(even) {
  border-left: 1px solid #ccc;
  width: calc(50% - 2px);
}

.nav-tabs li {
  float: left;
  margin-right: 4px;
}
.ft{
  padding-top: 20px;
  text-align: left;
  line-height: 30px;
}

/* pc */
@media only screen and (min-width: 600px)  {
  .col-2-item{
        font-size: 32px;
        line-height:100px;
        text-align: center;
}
.col-2-item icon{
 font-size: 60px;

}
.content{
  width: 1280px;
  margin: auto
}
.col-22-item .title{
 line-height:50px;
 font-size: 32px;
 
}
.col-25-item .title{
 line-height:50px;
 font-size: 32px;
 
}
.col-25-item img{
 width:300px;
}
.col-25-item .name{
 line-height:40px;
 font-size: 20px;
 margin-bottom:20px;
 background-color:#eee;
}
}

  /*phone*/
@media only screen and (min-width: 0px) and (max-width: 420px) {
	.col-2-item{
        font-size: 16px;
        line-height:50px;
        text-align: center;
}
.col-2-item icon{
 font-size: 32px;

}
.col-4-item{
        font-size: 16px;
        text-align: center;
}
.col-4-item icon{
 font-size: 32px;
}
.col-25-item img{
 width:160px;
}
}


</style>
