<template>
     
<div  class="bottomTab" >
    <el-row :gutter="10">


    <el-col
      :xs="6"
      :sm="6"
      :md="6"
      :lg="6"
      :xl="6"
        v-for="child  in module.children"
      :key="child"
      @click="navigateTo(child.url,global.router)"
    >
    
      <div :class=" 'grid-content '+  (child.name==props.activeName?'tabSelected':'') ">
       <icon
        :class="'iconfont icon-'+child.icon"></icon>
      <p>{{child.name}}</p>
       </div>
    </el-col>


  </el-row>
    </div>
  
</template>
  
     <script  setup>
    
import { inject, reactive, ref, watch, onMounted } from "vue";
import { ElMessage  } from "element-plus";
import {  isNull, navigateTo } from "@/api/common"; //常用方法

const props = defineProps({ //视频组件
  activeName: {
    type: String,
    default: "首页",
  }, 
  
});

const global = inject("global");
let module = reactive({}); //当前用户

onMounted(() => {
  //页面初始化完成后，调用
  var bottomTab = JSON.parse(localStorage.getItem("BottomTab"))
  if (isNull(bottomTab)) {
    //判断下是否有登录
    ElMessage("未找到底部导航配置" );
    return;
  }
  Object.assign(module,bottomTab);
});

</script>
    

